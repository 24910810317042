import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MdxLayout.tsx";
import SEO from 'src/components/SEO';
import MdxCard from 'src/components/MdxCard';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Enable Theme System" mdxType="SEO" />
    <MdxCard mdxType="MdxCard">
      <h1 {...{
        "id": "enable-theme-system"
      }}>{`Enable Theme System`}</h1>
      <blockquote>
        <p parentName="blockquote">{`Note: If you use our `}<a parentName="p" {...{
            "href": "/ui/guides/start-from-gatsby-template"
          }}>{`gatsby-admin-template kit`}</a>{` or `}<a parentName="p" {...{
            "href": "/ui/guides/start-from-nextjs-template"
          }}>{`nextjs-admin-template kit`}</a>{` then you already have the Advanced setup in place.`}</p>
      </blockquote>
      <h2 {...{
        "id": "normal-setup"
      }}>{`Normal Setup`}</h2>
      <p>{`To enable theme you must use `}<a parentName="p" {...{
          "href": "https://www.styled-components.com/docs"
        }}>{`styled-components`}</a>{` package.`}</p>
      <p><code parentName="p" {...{
          "className": "language-text"
        }}>{`createTheme`}</code>{` function take 2 args first one theme name second take object of settings that need to custom theme or use it in your styled components`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "jsx"
      }}><pre parentName="div" {...{
          "className": "language-jsx"
        }}><code parentName="pre" {...{
            "className": "language-jsx"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` React`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` useState `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'react'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// import ThemeProvider from styled-components to give it theme values`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` ThemeProvider `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'styled-components'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` Layout `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/ui/Layout'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` createTheme `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/theme'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`default`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`function`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`LayoutPage`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`props`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// define default theme and direction`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`theme`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` setTheme`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`useState`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'default'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`dir`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` setDir`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`useState`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'ltr'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// Change theme`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`changeTheme`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`newTheme`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token function"
            }}>{`setTheme`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`newTheme`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// Change Direction from Ltr to Rtl`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`changeDir`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` newDir `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` dir `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`===`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'ltr'`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'rtl'`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'ltr'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token function"
            }}>{`setDir`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`newDir`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// to enable direction you must send dir here and in layout component`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`ThemeProvider`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`theme`}</span><span parentName="span" {...{
                "className": "token script language-javascript"
              }}><span parentName="span" {...{
                  "className": "token script-punctuation punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`{`}</span><span parentName="span" {...{
                  "className": "token function"
                }}>{`createTheme`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`theme`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` dir`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` borderRadius`}<span parentName="span" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="span" {...{
                  "className": "token string"
                }}>{`'0.25rem'`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` outlineWidth`}<span parentName="span" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="span" {...{
                  "className": "token string"
                }}>{`'0.375rem'`}</span>{` `}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`}`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token plain-text"
            }}>{`
      `}</span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`Layout`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`dir`}</span><span parentName="span" {...{
                "className": "token script language-javascript"
              }}><span parentName="span" {...{
                  "className": "token script-punctuation punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`dir`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`}`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span><span parentName="code" {...{
              "className": "token plain-text"
            }}>{`
    `}</span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`ThemeProvider`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre></div>
      <h5 {...{
        "id": "global-style"
      }}>{`Global style`}</h5>
      <p>{`You must enable Global style make new file with any name will be like this example:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` createGlobalStyle `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'styled-components'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` GlobalStyle `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/ui'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` SimpleLayout `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` createGlobalStyle`}<span parentName="code" {...{
              "className": "token template-string"
            }}><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`
  `}</span><span parentName="span" {...{
                "className": "token interpolation"
              }}><span parentName="span" {...{
                  "className": "token interpolation-punctuation punctuation"
                }}>{`\${`}</span>{`GlobalStyle`}<span parentName="span" {...{
                  "className": "token interpolation-punctuation punctuation"
                }}>{`}`}</span></span><span parentName="span" {...{
                "className": "token string"
              }}>{`
  html{
    font-size: 16px;
  }
`}</span><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <p>{`Then you need to import in LayoutPage see example:`}</p>
      <div {...{
        "className": "gatsby-highlight has-highlighted-lines",
        "data-language": "jsx"
      }}><pre parentName="div" {...{
          "className": "language-jsx"
        }}><code parentName="pre" {...{
            "className": "language-jsx"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` SimpleLayout `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'./SimpleLayout'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`


`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`ThemeProvider`}</span></span>{`
      `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`theme`}</span><span parentName="span" {...{
                "className": "token script language-javascript"
              }}><span parentName="span" {...{
                  "className": "token script-punctuation punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`{`}</span><span parentName="span" {...{
                  "className": "token function"
                }}>{`themes`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`theme`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` dir`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` borderRadius`}<span parentName="span" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="span" {...{
                  "className": "token string"
                }}>{`'0.25rem'`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` outlineWidth`}<span parentName="span" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="span" {...{
                  "className": "token string"
                }}>{`'0.375rem'`}</span>{` `}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`}`}</span></span>{`
    `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token plain-text"
            }}></span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}><span parentName="span" {...{
                "className": "token plain-text"
              }}>{`      `}</span><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token tag"
                }}><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`<`}</span><span parentName="span" {...{
                    "className": "token class-name"
                  }}>{`SimpleLayout`}</span></span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`/>`}</span></span><span parentName="span" {...{
                "className": "token plain-text"
              }}></span></span><span parentName="code" {...{
              "className": "token plain-text"
            }}>{`      `}</span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`Layout`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`dir`}</span><span parentName="span" {...{
                "className": "token script language-javascript"
              }}><span parentName="span" {...{
                  "className": "token script-punctuation punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`dir`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`}`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span><span parentName="code" {...{
              "className": "token plain-text"
            }}></span>{`
`}<span parentName="code" {...{
              "className": "token plain-text"
            }}>{`      ...`}</span></code></pre></div>
      <div className="note note-info section-end">
  <div className="note-title">Note</div>
  <div className="note-body">
    Variables are accessible simply using a call of `$
    {({
            theme
          }) => theme.variables}` function in styled components.
  </div>
      </div>
      <h2 {...{
        "id": "advanced-setup"
      }}>{`Advanced setup`}</h2>
      <p><strong parentName="p">{`When`}</strong>{`: You need to add multiple themes.`}</p>
      <p>{`This setup assumes that you have gone through the `}<em parentName="p">{`Normal Setup`}</em>{` steps but you will take `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`createTheme`}</code>{` function and custom it`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` createTheme `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/theme'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`function`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`themeService`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`theme`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` dir`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`switch`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`theme`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`case`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'corporate'`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{`
      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`createTheme`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`theme`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` dir`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`...`}</span>{`customCorporate `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`case`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'dark'`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{`
      `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// this will take all default theme variables and over it with your custom variables`}</span>{`
      `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// if you want to inherit from another theme you can pass cosmic or corporate in first args`}</span>{`
      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`createTheme`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'corporate'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` dir`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`...`}</span>{`customDark `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`case`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'cosmic'`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`default`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{`
      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`createTheme`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`theme`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` dir `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token comment"
            }}>{`//  you can custom UI theme`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` customCorporate `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  borderRadius`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'0.25rem'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`

  outlineWidth`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'0.375rem'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  outlineColor`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasicTransparent200'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`

  scrollbarColor`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'backgroundBasicColor4'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  scrollbarBackgroundColor`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'backgroundBasicColor2'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  scrollbarWidth`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'0.3125rem'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`

  shadow`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1)'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`

  dividerColor`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'borderBasicColor3'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  dividerStyle`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'solid'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  dividerWidth`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'1px'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` customDark `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  borderRadius`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'0.25rem'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`

  outlineWidth`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'0.375rem'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  outlineColor`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasicTransparent200'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`

  scrollbarColor`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'backgroundBasicColor4'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  scrollbarBackgroundColor`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'backgroundBasicColor2'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  scrollbarWidth`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'0.3125rem'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`

  shadow`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1)'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`

  dividerColor`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'borderBasicColor3'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  dividerStyle`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'solid'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  dividerWidth`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'1px'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <p>{`After you end your `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`themeService`}</code>{` function you need to add it in your layout page`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "jsx"
      }}><pre parentName="div" {...{
          "className": "language-jsx"
        }}><code parentName="pre" {...{
            "className": "language-jsx"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` React`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` useState `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'react'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` ThemeProvider `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'styled-components'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` themeService `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'./themeService'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` SimpleLayout `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'./SimpleLayout'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`default`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`function`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`LayoutPage`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`props`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// define default theme and direction`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`theme`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` setTheme`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`useState`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'default'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`dir`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` setDir`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`useState`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'ltr'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// Change theme`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`changeTheme`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`newTheme`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token function"
            }}>{`setTheme`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`newTheme`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// Change Direction from Ltr to Rtl`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`changeDir`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` newDir `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` dir `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`===`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'ltr'`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'rtl'`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'ltr'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token function"
            }}>{`setDir`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`newDir`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// add custom theme function here and give it theme name and direction value`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`ThemeProvider`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`theme`}</span><span parentName="span" {...{
                "className": "token script language-javascript"
              }}><span parentName="span" {...{
                  "className": "token script-punctuation punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`{`}</span><span parentName="span" {...{
                  "className": "token function"
                }}>{`themeService`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`theme`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` dir`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`}`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token plain-text"
            }}>{`
      `}</span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`SimpleLayout`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span><span parentName="code" {...{
              "className": "token plain-text"
            }}>{`
      `}</span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`Layout`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`dir`}</span><span parentName="span" {...{
                "className": "token script language-javascript"
              }}><span parentName="span" {...{
                  "className": "token script-punctuation punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`dir`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`}`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span><span parentName="code" {...{
              "className": "token plain-text"
            }}>{`
    `}</span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`ThemeProvider`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre></div>
      <h2 {...{
        "id": "related-articles"
      }}>{`Related Articles`}</h2>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/themes/theme-system"
          }}>{`Theme System Concepts`}</a>{`.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/themes/default"
          }}>{`Default Theme variables table`}</a>{`.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/themes/cosmic"
          }}>{`Cosmic Theme variables table`}</a>{`.`}</li>
      </ul>
    </MdxCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      